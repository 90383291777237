import Vue from 'vue'
import VueRouter from 'vue-router'
import {storage} from "../unit/units";
import {Notify} from 'vant';
Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('../view/index')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../view/login')
    },
    {
        path: '/salary',
        name: 'salary',
        component: () => import('../view/salary')
    },
    {
        path: '/birthdayWishes',
        name: 'birthdayWishes',
        component: () => import('../view/birthdayWishes')
    },
    {
        path: '/salary-list',
        name: 'salary-list',
        component: () => import('../view/salary-list')
    },
    {
        path: '/particulars/:year/:month',
        name: 'particulars',
        component: () => import('../view/particulars')
    },
    {
        path: '/yearEndBonus/:id',
        name: 'yearEndBonus',
        component: () => import('../view/yearEndBonus')
    },
    {
        path: '/salaryAdjustment/:id',
        name: 'salaryAdjustment',
        component: () => import('../view/salaryAdjustment')
    }
]
const router = new VueRouter({
    mode:'history',
    routes
})
/**
 * 设备验证
 */
const verfEquipment=()=>{
    let sUserAgent = navigator.userAgent.toLowerCase();
    let bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    let bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    let bIsMidp = sUserAgent.match(/midp/i) == "midp";
    let bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    let bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    let bIsAndroid = sUserAgent.match(/android/i) == "android";
    let bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    let bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
    if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
        return 1
    } else {
      return 2
    }
}
router.beforeEach((to, from, next) => {
    let filetrName =['login']
    if(filetrName.includes(to.name)){
        next()
    }
    //浏览器验证;
    if(verfEquipment()===2 && to.name !=='login'&&to.name!=='birthdayWishes'){
        Notify({
            type: 'danger',
            message: '工资条属于私密信息,当前暂不支持网页版,请使用手机查看！'
        })
        next({
            path:'/login'
        })
    }
    if(!filetrName.includes(to.name)){
        if(!storage.get('token')){
            next({
                path:'/login'
            })
        }else {
            next()
        }
    }
})

export default router


