import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const state = {
    userInfo: '',
    defaultAvatar: require('../assets/img/defalutAvatar.png')
}
const mutations = {
    /**
     * 设置用户基本信息
     */
    setUserInfo(state, data) {
        state.userInfo = data
        localStorage.setItem('userInfo', JSON.stringify(data))
    }
}

/**
 * 缓存加载
 */
for (let stateKey in state) {
    if (localStorage.getItem(stateKey)) {
        try {
            state[stateKey] = JSON.parse(localStorage.getItem(stateKey))
        } catch (error) {
            state[stateKey] = localStorage.getItem(stateKey)
        }
    }
}
export default new Vuex.Store({
    state,
    mutations
})
