import {Button,Empty,Image,Row,Col,Icon,DatetimePicker,Loading,Toast} from "vant";
import Vue from 'vue'
Vue.use(Button)
Vue.use(Row)
Vue.use(Col)
Vue.use(Image)
Vue.use(Icon)
Vue.use(DatetimePicker)
Vue.use(Loading)
Vue.use(Empty)
Vue.use(Toast)
