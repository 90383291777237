import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from './store'
import './prototype'
import 'amfe-flexible'
import './assets/sty/rest.css'
import './assets/sty/common.css'
import'./assets/icon/iconfont.css'
import './vant'
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
