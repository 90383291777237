import moment from 'moment'
export const storage = {
    /**
     * 清除所有
     */
    removeAll() {
        localStorage.clear()
    },
    /**
     *
     *设置名称
     */
    set(name, data) {
        localStorage.setItem(name, data)
    },
    /**
     * 获取名称
     */
    get(name) {
        return localStorage.getItem(name)
    },
}
export const watermark={
    /**
     * 设置水印
     */
    setWatermark({id,time=moment().format('YYY/MM/DD HH:mm:ss'),userName}) {
        if(!id){
            window.console.error('id值参数未传')
            return false
        }
        let node = document.getElementById(id)
        let w = node.offsetWidth
        let h = node.offsetHeight
        let div = document.createElement('div')
        div.style.width =w+'px'
        div.style.height=h+'px'
        div.id ='watermark'
        div.style.position= 'fixed'
        div.style.left=0
        div.style.top=0
        div.style.zIndex=10000
        div.style.pointerEvents='none'
        let can = document.createElement('canvas')
        can.width = w/3
        can.height = 120
        let canvas = can.getContext('2d')
        canvas.rotate(-20*Math.PI/180);
        canvas.textAlign = 'center'
        canvas.fillStyle = "rgba(17, 17, 17, .1)";
        canvas.font = '12px Vedana'
        canvas.fillText(userName+'|'+userName, (w/3)/3, 60,   can.width)
        canvas.fillText(time, (w/3)/3, 90,   can.width)
        div.style.backgroundImage="url("+can.toDataURL("image/png")+")";
        node.append(div)
    },
    /**
     * 移除水印效果
     */
    removeWatermark(id) {
        id && document.getElementById(id).remove()
    }
}

